import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { format, startOfToday } from 'date-fns';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';
import { colorValidator, stringToColor } from '@fuse/hooks/AvatarColor';
import { setPosition } from './mapDataSlice';

export const getUsers = createAsyncThunk(
  'LocationApp/MapData/Users/getUsers',
  async (ws, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getUsersLocation(ws));

    const data = await response.data.map((e) => ({
      ...e,
      typeMarker: 'user',
      visible: true,
    }));

    if (data.length > 0) {
      // dispatch(setPosition({ coords: data[0].point.coordinates, zoom: 12, setPosition: true }));
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_USERS_FOUND'), variant: 'warning' }));
    }

    return data;
  }
);

export const getUserDetail = createAsyncThunk(
  'LocationApp/MapData/Users/getUserDetail',
  async ({ ws, user }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.getUserLocationDetail(ws, user.id));
    return { ...user, details: data };
  }
);

export const getUserTracking = createAsyncThunk(
  'LocationApp/MapData/Users/getUserTracking',
  async ({ ws, id }, { dispatch, getState }) => {
    const user = getState().LocationApp.Users.entities[id];
    const { data } = await axios.get(
      ApiRoutes.getUserLocationTracking(ws, user.user_data.id, { start: startOfToday() })
    );

    if (data.length === 0) {
      dispatch(
        showMessage({
          message: t('locationApp:NO_TRACKING', {
            name: `${user.user_data.name} ${user.user_data.last_name}`,
          }),
          variant: 'warning',
        })
      );
    }
    return { ...user, tracking: data, showTracking: true };
  }
);

export const getUserTrackingByDate = createAsyncThunk(
  'LocationApp/MapData/Users/getUserTracking',
  async ({ ws, userID, dates }, { rejectWithValue, dispatch, getState }) => {
    const user = getState().LocationApp.Users.entities[userID];

    let idRoute = '';
    let label = '';
    if (dates.start) {
      idRoute += Math.floor(dates.start.getTime() / 10000);
      label += format(dates.start, 'dd/MM/yyyy, h:mm a');
    }
    idRoute += '-';
    label += ' - ';
    if (dates.end) {
      idRoute += Math.floor(dates.end.getTime() / 10000);
      label += format(dates.end, 'dd/MM/yyyy, h:mm a');
    }
    const routes = user.routes ? [...user.routes] : [];
    if (routes.findIndex((x) => x.id === idRoute) === -1) {
      const { data } = await axios.get(
        ApiRoutes.getUserLocationTracking(ws, user.user_data.id, dates)
      );

      if (data.length === 0) {
        dispatch(
          showMessage({
            message: t('locationApp:NO_MEASUREMENTS_FOR_THESE_DATES', { dates: label }),
            variant: 'warning',
          })
        );
        throw rejectWithValue(t('locationApp:NO_MEASUREMENTS_FOR_THESE_DATES', { dates: label }));
      }

      routes.push({
        label,
        id: idRoute,
        color: stringToColor(idRoute),
        tracking: data,
        show: true,
        visibilityMarkers: false,
      });

      dispatch(
        setPosition({
          coords: [data[0].lat, data[0].lng],
          zoom: 15,
          setPosition: true,
        })
      );
    }

    return { ...user, routes };
  }
);

export const setUserSocked = createAsyncThunk(
  'LocationApp/MapData/Users/setUserSocked',
  async (user, { dispatch, getState }) => {
    const userCurrent = getState().LocationApp.Users.entities[user.user_location_id];

    const newData = {
      ...userCurrent,
      ...user,
      id: user.user_location_id,
    };

    delete user.user_location_id;

    newData.details = user;

    if (newData.tracking) {
      newData.tracking = [user, ...userCurrent.tracking];
    }

    return newData;
  }
);

export const setUserPathMarkersVisility = createAsyncThunk(
  'LocationApp/MapData/Users/setUserPathMarkersVisility',
  async (userId, { dispatch, getState }) => {
    const userCurrent = getState().LocationApp.Users.entities[userId];

    const newData = {
      ...userCurrent,
      visibilityMarkers: !userCurrent.visibilityMarkers,
    };
    return newData;
  }
);

export const searchUsers = createAsyncThunk(
  'LocationApp/MapData/Users/searchUsers',
  async (params, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.searchUsers(params.ws, params.search));
    const { ids, entities } = getState().LocationApp.Users;
    const newEntities = {};

    const data = await response.data.map((e) => ({ ...e, typeMarker: 'user' }));

    if (data.length > 0) {
      dispatch(setPosition({ coords: [data[0].lat, data[0].lng], zoom: 12, setPosition: true }));
      data.forEach((e) => {
        if (ids.includes(e.id)) {
          ids.push(e.id);
        }
        newEntities[e.id] = e;
      });
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_USERS_FOUND'), variant: 'warning' }));
    }
    const results = { ...entities, ...newEntities };
    return Object.keys(results).map((key) => results[key]);
  }
);

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => state.LocationApp.Users
);

const initialState = usersAdapter.getInitialState({});

const usersSlice = createSlice({
  name: 'LocationApp/MapData/Users',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      const { user, loading } = action.payload;
      state.entities[user].loading = loading;
    },
    setVisibilityUser: (state, action) => {
      const { user, visible } = action.payload;
      state.entities[user].visible = visible;
    },
    setVisibilityRoute: (state, action) => {
      const { user, routeIndex, show } = action.payload;
      state.entities[user].routes[routeIndex].show =
        show || !state.entities[user].routes[routeIndex].show;
    },
    setColorRoute: (state, action) => {
      const { user, routeIndex, color } = action.payload;
      if (colorValidator.test(color)) {
        state.entities[user].routes[routeIndex].color = color;
      }
    },
    setVisibilityMarkersRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes[routeIndex].visibilityMarkers =
        !state.entities[user].routes[routeIndex].visibilityMarkers;
    },

    logicalDeleteRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes[routeIndex].delete = true;
    },
    physicalDeleteRoute: (state, action) => {
      const { user, routeIndex } = action.payload;
      state.entities[user].routes.splice(routeIndex, 1);
    },
    setVisibilityPath: (state, action) => {
      const user = action.payload;
      state.entities[user].showTracking = !state.entities[user].showTracking;
    },
    resetUsersState: () => initialState,
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [searchUsers.fulfilled]: usersAdapter.setAll,
    [getUserDetail.fulfilled]: usersAdapter.setOne,
    [getUserTracking.fulfilled]: usersAdapter.setOne,
    [setUserSocked.fulfilled]: usersAdapter.setOne,
    [setUserPathMarkersVisility.fulfilled]: usersAdapter.setOne,
  },
});
export const {
  setVisibilityRoute,
  setColorRoute,
  setVisibilityPath,
  setVisibilityUser,
  logicalDeleteRoute,
  physicalDeleteRoute,
  setVisibilityMarkersRoute,
  setLoading,
  resetUsersState,
} = usersSlice.actions;

export default usersSlice.reducer;
