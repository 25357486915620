import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { roleChecker } from 'app/store/userSlice';

import { formSelector, getForms, selectForms, setPage } from '../store/FormsSlice';
import FormItem from './FormItem';

function FormList() {
  const dispatch = useDispatch();
  const forms = useSelector(selectForms);
  const navigate = useNavigate();
  const { isLoading } = useSelector(formSelector);
  const { formId, wsId } = useParams();
  const { t } = useTranslation('assignmentsApp');
  const scrollRef = useRef(null);
  const checkRole = useSelector(roleChecker);
  const [searchParams] = useSearchParams();

  const newForm = () => navigate(`./new${window.location.search}`, { replace: true });

  useEffect(() => {
    const urlPage = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1;

    dispatch(setPage(urlPage));
    dispatch(getForms({ wsId, paramsApi: { page: urlPage, search: searchParams.get('search') } }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wsId]);

  // this useEfect is for reboot the scroll when change page , for start of begin of list
  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="h-full" ref={scrollRef}>
        <FuseLoading />
      </div>
    );
  }

  if (!isLoading && forms.length === 0) {
    return (
      <div className="h-full w-full flex flex-col justify-center items-center" ref={scrollRef}>
        <FuseSvgIcon className="text-48" size={300} color="secondary">
          material-solid:sentiment_dissatisfied
        </FuseSvgIcon>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32  tracking-tight"
        >
          {t('NO_FORMS_SHOW')}
        </Typography>
      </div>
    );
  }

  return (
    <div className="p-32 flex-auto h-0" ref={scrollRef}>
      <div
        className="flex flex-wrap -m-4 mt-4 "
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          justifyContent: 'space-evenly',
          justifyItems: 'center',
          alignContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {forms.map((form) => (
          <FormItem key={form.id} form={form} />
        ))}
      </div>
      <div className="absolute right-24 bottom-36">
        {!formId && (checkRole('platform_admin') || checkRole('platform_support')) && (
          <Tooltip title={t('NEW_FORM')} placement="top">
            <Fab color="secondary" size="small" aria-label="remove" onClick={newForm}>
              <FuseSvgIcon size={20}>material-solid:add</FuseSvgIcon>
            </Fab>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default FormList;
