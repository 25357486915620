const locale = {
  ACCEPT: 'Aceptar',
  CANCEL: 'Cancelar',
  CODE: 'Código',
  CODE_ERROR: 'Ocurrió un error al procesar su código',
  CODE_NOT_FOUND: 'No se encontró un código de invitación para tu usuario',
  CONFIRM: 'Confirmar',
  COUNTRY: 'País',
  COUNTRY_DESC: 'Selecciona el pais al que pertenece el Escritorio',
  CREATE_WORKSPACE: 'Crear Escritorio',
  DESCRIPTION: 'Descripción',
  ERROR_CREATING_WS: 'Error al crear Escritorio',
  HAS_BEEN_JOINED_WORKSPACE: 'Has sido unido al escritorio "{{wsName}}"',
  HAVE_LEFT_WS: 'Has abandonado el Escritorio "{{name}}"',
  JOIN_WORKSPACE: 'Unirse a un Escritorio',
  JOIN_WORKSPACE_DESC: 'Introduce el código compartido por el administrador del Escritorio',
  MODULE_DESC: 'Selecciona los módulos que tendrá tu Escritorio',
  MODULES: 'Módulos',
  NAME: 'Nombre',
  NAME_ALREADY_IN_USE: 'El nombre "{{wsName}}" ya está en uso',
  NAME_CANNOT_EMPTY: 'El campo nombre no puede estar vacío',
  NAME_IN_USE: 'Ese nombre ya esta en uso',
  NEW_WORKSPACE_TITLE: 'Nuevo Escritorio',
  NEXT: 'Siguiente',
  NO_WS_FOUND: 'No se encontraron Escritorios',
  PREVIOUS: 'Anterior',
  ROLE_ORG_ADMIN: 'Administrador',
  ROLE_ORG_READ: 'Lector',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Soporte',
  ROLE_WORKSPACE: '',
  SELECT: 'Seleccionar',
  SELECT_WORKSPACE: 'Seleccione un Escritorio',
  SIGN_OFF: 'Cerrar sesión',
  TASK_MODULE: 'Módulo de tareas',
  TIME_ZONE: 'Zona horaria',
  TIME_ZONE_DESC: 'Selecciona que zona horaria aplica en tu Escritorio',
  TRACKING_MODULE: 'Módulo de ubicación',
  VERIFY: 'Verificar',
  WORKSPACE_DESC:
    'Un Escritorio es un ambiente de trabajo independiente. Los usuarios, tareas, visitas y otros elementos son diferentes en cada Escritorio',
  WORKSPACE_HAS_CREATED: 'Se ha creado el Escritorio "{{wsName}}"',
  WORKSPACE_WILL_CREATED: 'Se creará el Escritorio "{{wsName}}"',
};

export default locale;
