const mainApi = process.env.REACT_APP_API_HOST;

const ApiRoutes = {
  login: {
    getToken: `${mainApi}/api/auth/user/`,
    getUserInfo: `${mainApi}/api/v1/users/my-data/`,
    registerUser: `${mainApi}/api/auth/register/`,
  },

  assignmentsEndPoint: (ws, params) => {
    if (params) {
      let urlParams = '?';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params?.search) {
        urlParams += `&search=${params.search}`;
      }

      if (params.date_created_gte) {
        urlParams += `&date_created__gte=${params.date_created_gte}`;
      }

      if (params.date_created_lte) {
        urlParams += `&date_created__lte=${params.date_created_lte}`;
      }

      if (params.date_status_gte) {
        urlParams += `&date_status__gte=${params.date_status_gte}`;
      }

      if (params.date_status_lte) {
        urlParams += `&date_status__lte=${params.date_status_lte}`;
      }

      if (params.date_due_lte) {
        urlParams += `&date_due__lte=${params.date_due_lte}`;
      }

      if (params.date_status_lte) {
        urlParams += `&date_status__lte=${params.date_status_lte}`;
      }

      if (params?.status) {
        urlParams += `&status=${params.status}`;
      }

      if (params?.client) {
        urlParams += `&client=${params.client}`;
      }

      if (params?.project) {
        urlParams += `&project=${params.project}`;
      }

      if (params?.by_user) {
        urlParams += `&user=${params.by_user}`;
      }

      if (params?.team) {
        urlParams += `&team=${params.team}`;
      }

      if (params?.bookmark) {
        urlParams += `&bookmark=${params.bookmark}`;
      }

      if (params?.tags) {
        params?.tags.split(',').forEach((tag) => {
          urlParams += `&tags=${tag}`;
        });
      }

      if (params?.forms) {
        params?.forms.split(',').forEach((forms) => {
          urlParams += `&forms=${forms}`;
        });
      }

      return `${mainApi}/api/v1/workspaces/${ws}/assignments/${urlParams}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/assignments/`;
  },
  getUsersLocation: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-location/list-for-map/`,
  getAssignmentsLocation: (ws, { dateType = 'date_created', start, end, user, client, tags }) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map/?status=done`;

    if (start) {
      url += `&${dateType}__gte=${start.toISOString()}`;
    }

    if (end) {
      url += `&${dateType}__lte=${end.toISOString()}`;
    }

    if (client) {
      url += `&client=${client}`;
    }

    if (user) {
      url += `&user=${user}`;
    }

    if (tags?.length > 0) {
      tags.forEach((tagId) => {
        url += `&tags=${tagId}`;
      });
    }

    return url;
  },
  getUserLocationDetail: (ws, user) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-location/${user}/detail-for-map/`,
  getUserLocationTracking: (ws, user, { start, end }) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/user-location-register/list-for-map/?user=${user}`;
    if (start) {
      url += `&date_coords__gte=${start.toISOString()}`;
    }

    if (end) {
      url += `&date_coords__lte=${end.toISOString()}`;
    }
    return url;
  },
  getCheckInOutLocationsList: (ws, { user, start, end, tags }) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/checkinout/list-for-map/?`;

    if (start) {
      url += `&date_created__gte=${start.toISOString()}`;
    }

    if (end) {
      url += `&date_created__lte=${end.toISOString()}`;
    }

    if (user) {
      url += `&user=${user}`;
    }

    if (tags?.length > 0) {
      tags.forEach((tagId) => {
        url += `&tags=${tagId}`;
      });
    }
    return url;
  },
  getVehiclesLocation: (ws) => `${mainApi}/api/v1/workspaces/${ws}/gps-devices/all/`,
  getGeofencesLocation: (ws) => `${mainApi}/api/v1/workspaces/${ws}/geo-fence/all/`,
  getGeofences: (ws, { page = 1 }) => `${mainApi}/api/v2/workspaces/${ws}/geofences/?page=${page}`,
  getAllGeofencesBasicInfo: (ws) => `${mainApi}/api/v2/workspaces/${ws}/geofences/all-basic-info/`,
  geofenceEndPoint: (ws, geofence) => `${mainApi}/api/v2/workspaces/${ws}/geofences/${geofence}`,
  getGeofenceAlerts: (ws, geofence) =>
    `${mainApi}/api/v2/workspaces/${ws}/alerts/all-by-geofence/?geofences=${geofence}`,
  getMyPermissions: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-permissions/mine/`,
  searchGeofence: (ws, search) =>
    `${mainApi}/api/v2/workspaces/${ws}/geofence/all/?search=${search.replace(' ', '+')}`,
  searchUsers: (ws, search) =>
    `${mainApi}/api/v1/workspaces/${ws}/user-location/all-search/?search=${search.replace(
      ' ',
      '+'
    )}`,
  projectsEndPoint: (ws, params) => {
    let urlParams = '';
    if (params?.page || params?.search) {
      urlParams += '?';
    }

    if (params?.page) {
      urlParams += `page=${params.page}&`;
    }
    if (params?.search) {
      urlParams += `search=${params.search}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/projects/${urlParams}`;
  },
  getAllAlertsByType: (ws, type) =>
    `${mainApi}/api/v2/workspaces/${ws}/alerts/all-by-type/?type=${type}`,
  alertsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params.page) {
        urlParams += `page=${params.page}&`;
      }
      if (params.search) {
        urlParams += `search=${params.search}&`;
      }

      if (params.date_created__gte) {
        urlParams += `date_created__gte=${params.date_created__gte}&`;
      }

      if (params.date_created__lte) {
        urlParams += `date_created__lte=${params.date_created__lte}&`;
      }
      if (params.type) {
        urlParams += `type=${params.type}&`;
      }

      if (params?.active !== null && params?.active !== undefined) {
        urlParams += `active=${params.active}&`;
      }

      if (params.apply_all_users !== null && params.apply_all_users !== undefined) {
        urlParams += `apply_all_users=${params.apply_all_users}&`;
      }

      if (params?.users) {
        params?.users.split(',').forEach((user) => {
          urlParams += `&users=${user}`;
        });
      }
    }
    return `${mainApi}/api/v2/workspaces/${ws}/alerts/${urlParams}`;
  },
  usersEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/users/`,
  teamsEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/teams/`,
  tagsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params?.page) {
      urlParams += `?page=${params.page}&`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/tags/${urlParams}`;
  },
  clientsEndPoint: (ws, params, clientId) => {
    if (clientId) {
      return `${mainApi}/api/v1/workspaces/${ws}/clients/${clientId}/`;
    }

    if (params) {
      if (params?.seller) {
        return `${mainApi}/api/v1/workspaces/${ws}/clients/for-seller/?seller=${params.seller}`;
      }

      let urlParams = '?approved=true&web-version=2.0.0';

      if (params?.page) {
        urlParams += `&page=${params.page}`;
      }
      if (params?.search) {
        urlParams += `&search=${params.search}`;
      }

      if (params.date_created__gte) {
        urlParams += `&date_created__gte=${params.date_created__gte}`;
      }

      if (params.date_created__lte) {
        urlParams += `&date_created__lte=${params.date_created__lte}`;
      }

      if (params?.teams) {
        params?.teams.split(',').forEach((team) => {
          urlParams += `&teams=${team}`;
        });
      }

      if (params?.sellers) {
        params?.sellers.split(',').forEach((seller) => {
          urlParams += `&seller=${seller}`;
        });
      }

      return `${mainApi}/api/v1/workspaces/${ws}/clients/${urlParams}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/clients/`;
  },
  csvLoadsEndPoint: (ws, params, csvLoadId) => {
    if (csvLoadId) {
      return `${mainApi}/api/v1/workspaces/${ws}/csv-load/${csvLoadId}/`;
    }

    let urlParams = '?';

    if (params?.page) {
      urlParams += `&page=${params.page}`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/csv-load/${urlParams}`;
  },
  csvLoadFile: (ws) => {
    return `${mainApi}/api/v1/workspaces/${ws}/load-csv-file/`;
  },
  clientValidateUniqueFields: (ws, client) => {
    let urlParams = `?name=${client.name}`;
    if (client.internal_code) {
      urlParams += `&internal_code=${client.internal_code}`;
    }

    if (client.invoice_tax_identifier) {
      urlParams += `&invoice_tax_identifier=${client.invoice_tax_identifier}`;
    }

    if (client.id) {
      urlParams += `&client_id=${client.id}`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/clients/exists-name/${urlParams}`;
  },
  getClientsLocation: (ws, params) => {
    let urlParams = '?';
    if (params.withCoords !== undefined) {
      urlParams += `&with-coords=${params.withCoords}`;
    }

    if (params.page !== undefined) {
      urlParams += `&page=${params.page}`;
    }

    return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map/${urlParams}`;
  },

  getClientsCount: (ws) => `${mainApi}/api/v1/workspaces/${ws}/clients/location-count/`,
  countriesEndPoint: () => `${mainApi}/api/v1/country/all/`,
  timeZonesEndPoint: () => `${mainApi}/api/v1/timezones/all/`,
  rolesListAll: (ws) => `${mainApi}/api/v1/workspaces/${ws}/roles/`,
  workspaceEndPoint: () => `${mainApi}/api/v1/workspaces/`,
  getWorkspaceSettings: (ws) => `${mainApi}/api/v1/workspaces/${ws}/settings/`,
  updateWorkspaceSettings: (ws) => `${mainApi}/api/v1/workspaces/${ws}/settings-update/`,
  organizationEndPoint: () => `${mainApi}/api/v1/organizations/`,
  getOrganizationBilling: (org) => `${mainApi}/api/v1/organizations/${org}/get-billing/`,
  getOrganizationContacts: (org) => `${mainApi}/api/v1/organizations/${org}/get-contacts/`,
  getOrganizationUsers: (org) => `${mainApi}/api/v1/organizations/${org}/users/`,
  setOrganizationBilling: (org, billing) =>
    `${mainApi}/api/v1/organizations/${org}/billing-info/${billing}/`,
  setOrganizationContact: (org, contact) =>
    `${mainApi}/api/v1/organizations/${org}/contacts/${contact}/`,
  getUserRole: (ws, id) => `${mainApi}/api/v1/workspaces/${ws}/roles/user-roles/?user=${id}`,
  setUserRole: (ws) => `${mainApi}/api/v1/workspaces/${ws}/roles/update-user-roles/`,
  updateUser: (ws, id) => `${mainApi}/api/v1/workspaces/${ws}/user-update/${id}/`,
  checkUser: (ws, email) => `${mainApi}/api/v1/workspaces/${ws}/user-exists/?email=${email}`,
  AddUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/add-user-to-workspace/`,
  inviteUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/invite-user-to-workspace/`,
  removeUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-remove/`,
  rolesEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/roles/`,
  permissionsEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/permissions/`,
  setSuperUser: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-permissions/set-superuser/`,
  getSuperUsers: (ws) => `${mainApi}/api/v1/workspaces/${ws}/user-permissions/all-superusers/`,
  changePassword: (ws) => `${mainApi}/api/v1/workspaces/${ws}/change-other-user-password/`,
  changeMyPassword: () => `${mainApi}/api/v1/users/change-password/`,
  confirmCodeWS: () => `${mainApi}/api/v1/confirm-invite-code/`,
  setLanguaje: () => `${mainApi}/api/v1/users/set-language/`,
  getAlertLocationList: (ws, { user, start = new Date(), end }) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/events/all/?type=max-speed&date_event__gte=${start.toISOString()}`;

    if (end) {
      url += `&date_event__lte=${end.toISOString()}`;
    }

    if (user) {
      url += `&user=${user}`;
    }

    return url;
  },
  getDailyRecord: (ws, { page = 1, start = new Date(), end, user, team }) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/user-location-daily-record/?page=${page}&date__gte=${start.toISOString()}`;

    if (end) {
      url += `&date_lte=${end.toISOString()}`;
    }

    if (user) {
      url += `&user=${user}`;
    }

    if (team) {
      url += `&team=${team}`;
    }

    return url;
  },
  createGeofence: (ws) => `${mainApi}/api/v2/workspaces/${ws}/geofence-create/`,
  updateGeofence: (ws, geofenceId) =>
    `${mainApi}/api/v2/workspaces/${ws}/geofence-update/${geofenceId}/`,
  placesEndPoint: (ws) => `${mainApi}/api/v1/workspaces/${ws}/places/`,
  dashboardData: (ws, params) => {
    if (params?.date) {
      return `${mainApi}/api/v1/workspaces/${ws}/statistics/${params.date}/date/`;
    }
    return `${mainApi}/api/v1/workspaces/${ws}/statistics/current/`;
  },
  dashboardTimeline: (ws, params) => {
    let url = `${mainApi}/api/v1/workspaces/${ws}/statistics/`;
    if (params?.page) {
      url += `?page=${params.page}`;
    }
    return url;
  },

  assignmentsListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/assignments/list-for-map-current-day/`;
  },
  checkInOutListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/checkinout/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/checkinout/list-for-map-current-day/`;
  },
  clientsListForMap: (ws, params) => {
    if (params?.date)
      return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map-given-day/?date=${params.date}`;
    return `${mainApi}/api/v1/workspaces/${ws}/clients/list-for-map-current-day/`;
  },
  formsEndPoint: (ws, params) => {
    let urlParams = '';

    if (params) {
      urlParams += '?';

      if (params.noPagination) {
        return `${mainApi}/api/v1/workspaces/${ws}/forms/all-list`;
      }

      if (params.page) {
        urlParams += `page=${params.page}&`;
      }
      if (params.search) {
        urlParams += `search=${params.search}&`;
      }
    }

    return `${mainApi}/api/v1/workspaces/${ws}/forms/${urlParams}`;
  },
};

export default ApiRoutes;
