/* eslint-disable camelcase */
import '@mock-api';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
// import axios from 'axios';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import { Provider, ErrorBoundary } from '@rollbar/react';

import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';
import { selectuserWorkspacePermissions } from './store/userWorkspacePermissionsSlice';
import { selectWorkspace } from './store/workspaceSlice';

/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = '';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// setDefaultOptions({ locale: es });

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  environment: process.env.ROLLBAR_ENVIRONMENT,
  enabled: process.env.ROLLBAR_ENABLED === 'true',
};

const App = () => {
  const user = useSelector(selectUser);
  const userPermission = useSelector(selectuserWorkspacePermissions);
  const workspace = useSelector(selectWorkspace);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
          <FuseTheme theme={mainTheme} direction={langDirection}>
            <AuthProvider>
              <BrowserRouter>
                <FuseAuthorization
                  userRole={user.role}
                  workspace={workspace}
                  userPermission={userPermission}
                >
                  <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    classes={{
                      containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                    }}
                  >
                    <FuseLayout layouts={themeLayouts} />
                  </SnackbarProvider>
                </FuseAuthorization>
              </BrowserRouter>
            </AuthProvider>
          </FuseTheme>
        </CacheProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export default withAppProviders(App)();
