import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getClientsPaginated = createAsyncThunk(
  'selectsData/clients/getClientsPaginated',
  async ({ wsId, page, search }, { dispatch, getState }) => {
    await dispatch(setIsClientsLoading(true));

    const {
      data: { results, count },
    } = await axios.get(ApiRoutes.clientsEndPoint(wsId, { page, search }));

    await dispatch(setClientProps({ page, count }));
    await dispatch(setIsClientsLoading(false));

    return results;
  }
);

export const getClientsPerUsers = createAsyncThunk(
  'selectsData/clients/getClientsPerUsers',
  async ({ wsId, seller }, { dispatch, getState }) => {
    await dispatch(setIsClientsLoading(true));

    const { data } = await axios.get(ApiRoutes.clientsEndPoint(wsId, { seller }));

    await dispatch(setClientProps({ seller }));

    await dispatch(setIsClientsLoading(false));

    return data;
  }
);

export const getClient = createAsyncThunk(
  'selectsData/clients/getClient',
  async ({ wsId, clientId }, { dispatch, getState }) => {
    await dispatch(setIsClientsLoading(true));
    const { selectedClient } = getState().selectsData.clients;
    if (selectedClient?.id === clientId || !clientId) {
      await dispatch(setIsClientsLoading(false));
      return;
    }

    await dispatch(setIsClientsLoading(true));

    const { data } = await axios.get(ApiRoutes.clientsEndPoint(wsId, {}, clientId));
    dispatch(setClientSelected(data));

    await dispatch(setIsClientsLoading(false));
  }
);

const clientsAdapter = createEntityAdapter({});

export const selectClientSelector = (state) => state.selectsData.clients;

export const { selectAll: selectClients, selectById: selectClientById } =
  clientsAdapter.getSelectors(selectClientSelector);

const initialState = clientsAdapter.getInitialState({
  isClientsLoading: true,
  count: 0,
  page: 1,
  selectedClient: null,
  sellerParam: null,
});

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setIsClientsLoading: (state, action) => {
      state.isClientsLoading = action.payload;
    },
    setClientProps: (state, action) => {
      const { page = 1, count = 0, seller = null } = action.payload;
      state.page = page;
      state.count = count;
      state.seller = seller;
    },
    setClientSelected: (state, action) => {
      state.selectedClient = action.payload;
    },

    resetClientState: () => initialState,
  },
  extraReducers: {
    [getClientsPaginated.fulfilled]: clientsAdapter.setAll,
    [getClientsPerUsers.fulfilled]: clientsAdapter.setAll,
  },
});

export const { setIsClientsLoading, resetClientState, setClientProps, setClientSelected } =
  clientSlice.actions;

export default clientSlice.reducer;
