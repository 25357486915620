import * as yup from 'yup';
import Fab from '@mui/material/Fab';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUsersAll } from 'app/store/selectsData/usersSlice';
import InformationTab from './tabs/InformationTab';
import UsersTab from './tabs/UsersTab';

import {
  addTeam,
  selectTeamById,
  setTeam,
  setSidebarProps,
  setDeleteModalProps,
} from '../../store/teamsSlice';

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottomWidth: '1px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
}));

const defaultValues = {
  name: '',
  description: '',
  users: [],
  users_data: [],
  user_in_charge: '',
  user_in_charge_data: {},
};

function TeamSidebarContent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('managementApp');
  const { teamId, wsId } = useParams();
  const { isUsersLoaded } = useSelector(({ selectsData }) => selectsData.users);
  const { numOfTab } = useSelector(({ managementApp }) => managementApp.teams.sidebarProps);
  const team = useSelector((state) => selectTeamById(state, teamId));
  const [isLoading, setLoading] = useState(true);

  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, teamId }));

  const onClose = () => {
    dispatch(setSidebarProps({ numOfTab: 0 }));
    navigate('..', { replace: true });
  };
  const onChangeTab = (e, numTab) => dispatch(setSidebarProps({ numOfTab: numTab }));

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = methods.formState;

  const saveTeam = () => dispatch(setTeam({ wsId, team: methods.getValues() }));

  const createTeam = async () => {
    const {
      payload: [{ id }],
    } = await dispatch(addTeam({ wsId, team: methods.getValues() }));
    navigate(`../${id}`, { replace: true });
  };

  useEffect(() => {
    if (teamId === 'new') {
      methods.reset({ workspace: wsId, ...defaultValues });
    } else {
      methods.reset(team);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, team]);

  useEffect(() => {
    if (!isUsersLoaded && teamId) {
      dispatch(getUsersAll(wsId)).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, teamId]);

  if (!teamId) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }
  return (
    <div className=" flex flex-col justify-around h-fullVH w-fullVW sm:h-full sm:w-full">
      <Header>
        <Tabs
          value={numOfTab}
          onChange={onChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          className="h-full"
        >
          <Tab className="h-full" label={t('INFORMATION')} />
          <Tab className="h-full" label={t('USERS')} />
        </Tabs>
        <IconButton onClick={onClose} className="text-48 mr-4 self-center">
          <FuseSvgIcon size={24} color="action">
            heroicons-solid:x
          </FuseSvgIcon>
        </IconButton>
      </Header>
      <Content>
        <FormProvider
          {...methods}
          noValidate
          className="flex flex-col justify-center w-full h-full mt-32"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {numOfTab === 0 && <InformationTab />}

          {numOfTab === 1 && <UsersTab />}
        </FormProvider>
      </Content>

      <div className="absolute right-12 bottom-12 flex gap-10">
        {teamId === 'new' ? (
          <Tooltip title={t('CREATE')} placement="top">
            <Fab
              color="secondary"
              size="small"
              aria-label="edit"
              onClick={createTeam}
              disabled={!isValid}
            >
              <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
            </Fab>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={t('DELETE')} placement="top">
              <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
                <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
              </Fab>
            </Tooltip>
            <Tooltip title={t('SAVE')} placement="top">
              <Fab
                color="secondary"
                size="small"
                aria-label="edit"
                onClick={saveTeam}
                disabled={!isValid}
              >
                <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

export default TeamSidebarContent;
