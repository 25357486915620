import Box from '@mui/material/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectRoleById } from '../../store/rolesSlice';

function RolesSidebarSuperusers(props) {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const { t } = useTranslation('managementApp');
  const { users_data: users } = useSelector((state) => selectRoleById(state, roleId));

  const onClose = () => navigate(`..`, { replace: true });

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.dark,
          paddingX: 2.5,
          paddingY: 3,
          display: 'flex',
          alignContent: 'center',
        })}
      >
        <Typography
          className="truncate font-bold flex-1 px-12 align-middle"
          color="common.white"
          variant="h6"
        >
          {t('SUPERUSERS')}
        </Typography>
        <IconButton onClick={onClose} className="text-48 mr-4 self-center">
          <FuseSvgIcon size={24} style={{ color: '#FFF' }}>
            heroicons-solid:x
          </FuseSvgIcon>
        </IconButton>
      </Box>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        {users.map(({ user_data: user }) => {
          return (
            <ListItem
              key={user.id}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:person
                  </FuseSvgIcon>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemText
                  id={user.id}
                  primary={`${user.name} ${user.last_name}`}
                  secondary={user.email}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
export default RolesSidebarSuperusers;
