const locale = {
  ALERT_DEMO: 'DEMO',
  alerts: 'Alerts',
  assignmentList: 'Assignment list',
  assignments: 'Assignments',
  clients: 'Clients',
  COMPANY_LOGO_CAN_HERE: 'Your company logo can go here',
  csv_load: 'CSV load',
  forms: 'Forms',
  loading: 'Loading',
  location: 'Location',
  management: 'Management',
  map: 'Map',
  myProfile: 'My profile',
  organization: 'Organization',
  organizations: 'Organizations',
  projects: 'Project',
  ROLE_ORG_ADMIN: 'Administrator',
  ROLE_ORG_READ: 'Reader',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Support',
  ROLE_WORKSPACE: '',
  scheduled_reports: 'Scheduled reports',
  SELECT_COUNTRY: 'Select a country',
  SELECT_LANGUAGE: 'Select a language',
  SELECT_TIME_ZONE: 'Select a time zone',
  settings: 'Settings',
  SIGN_OFF: 'Sign out',
  SUPPORT_MESSAGE: 'Hello! I need support',
  SUPPORT_MESSAGE_WS: 'Hello!+I+need+support+for+the+Workspace+"{{name}}"',
  tags: 'Tags',
  users: 'User',
  workspaces: 'Workspaces',
};

export default locale;
