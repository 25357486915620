import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  combineReducers,
  current,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { colorValidator, stringToColor } from '@fuse/hooks/AvatarColor';
import axios from 'axios';
import { t } from 'i18next';
import { MD5 } from 'crypto-js';
import ApiRoutes from 'src/app/services/ApiRoutes';

import { setPosition } from './mapDataSlice';

export const createCheckInOutGroup = createAsyncThunk(
  'LocationApp/CheckInOut/groups/createCheckInOutGroup',
  async (
    { ws, params: { user = null, start = null, end = null, tags = [] } },
    { dispatch, getState }
  ) => {
    const userObj = getState().LocationApp.Users.entities[user];
    let id = '';

    if (start) {
      id += Math.floor(start.getTime() / 10000);
    }
    id += ':';

    if (end) {
      id += Math.floor(end.getTime() / 10000);
    }

    id += ':';

    tags.forEach((tag) => {
      id += tag.id;
    });

    id += ':';

    if (userObj) {
      id += userObj.id;
    }

    const { payload: checkInOuts } = await dispatch(
      getCheckInOutList({
        ws,
        params: { user: userObj?.user_data.id, start, end, tags: tags.map((tag) => tag.id) },
      })
    );

    if (checkInOuts.length > 0) {
      dispatch(
        setPosition({
          coords: [checkInOuts[0].lat, checkInOuts[0].lng],
          zoom: 12,
          setPosition: true,
        })
      );
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_CHECK_IN_OUTS_FOUND'), variant: 'warning' }));
      throw new Error(':(');
    }

    const ms5Id = MD5(id).toString();

    checkInOuts.forEach((checkInOut) =>
      dispatch(bindGroupCheckInOut({ idCheckInOut: checkInOut.id, idGroup: ms5Id }))
    );

    return {
      id: ms5Id,
      color: stringToColor(id),
      active: true,
      tags,
      user: userObj?.user_data,
      start,
      end,
    };
  }
);

export const deleteCheckInOutGroup = createAsyncThunk(
  'LocationApp/CheckInOut/groups/deleteCheckInOutGroup',
  async (idGroup, { dispatch, getState }) => {
    const { entities, ids } = getState().LocationApp.CheckInOut.list;
    ids.forEach(async (idCheckInOut) => {
      if (entities[idCheckInOut].groupsId.includes(idGroup)) {
        await dispatch(unbindGroupCheckInOut({ idCheckInOut, idGroup }));
      }
    });

    return idGroup;
  }
);

export const selectActiveGroup =
  ({
    LocationApp: {
      CheckInOut: { list, groups },
    },
  }) =>
  (idCheckInOut) => {
    const checkInOut = list.entities[idCheckInOut];
    if (!checkInOut) {
      return null;
    }
    const groupId = checkInOut.groupsId?.find((id) => Boolean(groups.entities[id]?.active));

    if (!groupId) {
      return null;
    }

    return groups.entities[groupId];
  };

const checkInOutGroupsAdapter = createEntityAdapter({});

export const { selectAll: selectCheckInOutGroups } = checkInOutGroupsAdapter.getSelectors(
  (state) => state.LocationApp.CheckInOut.groups
);
export const selectCheckInOutGroup = ({ LocationApp }) => LocationApp.CheckInOut.groups;

const initialGroupsState = checkInOutGroupsAdapter.getInitialState({});

const checkInOutGroupsSlice = createSlice({
  name: 'LocationApp/MapData/CheckInOut/groups',
  initialState: initialGroupsState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActiveGroup: (state, action) => {
      const { groupId, active } = action.payload;
      state.entities[groupId].active = active;
    },
    setColorGroup: (state, action) => {
      const { groupId, color } = action.payload;
      if (colorValidator.test(color)) {
        state.entities[groupId].color = color;
      }
    },
    logicalDeleteGroup: (state, action) => {
      const { groupId } = action.payload;
      state.entities[groupId].delete = true;
    },
    resetGroups: () => initialGroupsState,
  },
  extraReducers: {
    [createCheckInOutGroup.fulfilled]: checkInOutGroupsAdapter.upsertOne,
    [deleteCheckInOutGroup.fulfilled]: checkInOutGroupsAdapter.removeOne,
  },
});

export const { setActiveGroup, setColorGroup, logicalDeleteGroup, setLoading, resetGroups } =
  checkInOutGroupsSlice.actions;

// lists

export const getCheckInOutList = createAsyncThunk(
  'LocationApp/MapData/CheckInOut/list/getCheckInOutList',
  async ({ ws, params }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.getCheckInOutLocationsList(ws, params));

    return data.map((e) => ({ ...e, typeMarker: 'CheckInOut' }));
  }
);
export const deleteCheckInOut = createAsyncThunk(
  'LocationApp/MapData/CheckInOut/deleteCheckInOut',
  async (id, { dispatch, getState }) => id
);

const checkInOutListAdapter = createEntityAdapter({});

export const { selectAll: selectCheckInOutLists } = checkInOutListAdapter.getSelectors(
  (state) => state.LocationApp.CheckInOut.list
);

const initialListState = checkInOutListAdapter.getInitialState({});

const checkInOutListSlice = createSlice({
  name: 'LocationApp/MapData/CheckInOut/list',
  initialState: initialListState,
  reducers: {
    bindGroupCheckInOut: (state, { payload: { idCheckInOut, idGroup } }) => {
      const groups = current(state.entities[idCheckInOut]).groupsId
        ? [...current(state.entities[idCheckInOut]).groupsId]
        : [];

      groups.push(idGroup);
      state.entities[idCheckInOut].groupsId = groups;
    },
    unbindGroupCheckInOut: (state, { payload: { idCheckInOut, idGroup } }) => {
      const groups = current(state.entities[idCheckInOut]).groupsId
        ? [...current(state.entities[idCheckInOut]).groupsId]
        : [];

      const index = groups.indexOf(idGroup);
      if (index > -1) groups.splice(index, 1);
      state.entities[idCheckInOut].groupsId = groups;

      if (groups.length === 0) state.entities[idCheckInOut].delete = true;
    },
    resetList: () => initialListState,
  },
  extraReducers: {
    [getCheckInOutList.fulfilled]: checkInOutListAdapter.upsertMany,
    [deleteCheckInOut.fulfilled]: checkInOutListAdapter.removeOne,
  },
});

export const { bindGroupCheckInOut, unbindGroupCheckInOut, resetList } =
  checkInOutListSlice.actions;

const reducer = combineReducers({
  list: checkInOutListSlice.reducer,
  groups: checkInOutGroupsSlice.reducer,
});

export const resetCheckInOut = (dispatch) => {
  dispatch(resetList());
  dispatch(resetGroups());
};

export default reducer;
